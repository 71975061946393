import React from "react";
import { FaLinkedin, FaInstagram, FaTwitter } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

export const Footer = () => {
  return (
    <>
      <div className="w-full h-16 pt-2 flex justify-center items-center bg-black">
        <div className="w-10 mr-2 ">
            <a href="https://in.linkedin.com/in/akash-perumal-954106117" target="_blank" rel="noreferrer">
          <FaLinkedin size={40} color="white" />
            </a>
        </div>
        <div className="w-10 mr-2 ">
            <a href="https://www.twitter.com/codewithsky" target="_blank" rel="noreferrer">
          <FaTwitter size={40} color="white" />
            </a>
        </div>
        <div className="w-10 mr-2 ">
            <a href="https://instagram.com/enjoying_clicks?igshid=ZDc4ODBmNjlmNQ==" target="_blank" rel="noreferrer">
          <FaInstagram size={40} color="white" />
            </a>
        </div>
      </div>
    </>
  );
};
