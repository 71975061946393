import React from "react";
import { TimeLine } from "./components/mytimeline";
import { FiInstagram } from "react-icons/fi";
import { Cards } from "./components/cards";
import { Footer} from "./components/footer"

function Home() {
  return (
    <>
      <div className="bg-[url('https://images.unsplash.com/photo-1619665481428-101a5daa4d15?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1376&q=80')] justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col md:flex-row md:w-[90%] justify-center md:pb-10 items-center">
            <div className="md:w-1/2 md:flex md:justify-center md:items-center">
              <img
                className="w-72 h-72"
                src="https://shutterbugss.s3.ap-south-1.amazonaws.com/Akash.png"
                alt="myphoto"
              />
            </div>
            <div className="md:w-1/2 flex justify-center items-center p-5">
              <p className="font-cursive  font-bold text-xl text-white">
                Hi everyone, I am Akash (Sky😉) <br></br>
                The Versatile Developer <br></br>
                I am a tech enthusiast
                who loves to code,<br></br>
                and an avid photographer{" "}
                <a
                  target="_blank"
                  href="https://instagram.com/enjoying_clicks?igshid=ZDc4ODBmNjlmNQ=="
                  rel="noreferrer"
                >
                  <FiInstagram />
                </a>
                Join me on my journey of learning 📚,<br></br> Let's learn and
                explore 🌏 together
              </p>
            </div>
          </div>
        </div>
        <div className="md:flex md:flex-row">
          <div className="md:w-[50%] ">
            <p className="text-white md:ml-10 ml-4 pb-5">My Journey</p>
            <TimeLine />
          </div>
          <div className="md:w-[50%]">
          <p className="text-white md:ml-10 ml-4 pb-5"> My Achievements </p>
            <Cards />
          </div>
        </div>
      <div className="mt-4">
        <Footer/>
      </div>
      </div>
    </>
  );
}

export default Home;
