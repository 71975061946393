import logo from './logo.svg';
import './App.css';
import Home from './home';
import AboutMe from './pages/aboutMe';
import { Like } from './pages/likes';
import { HomePage } from './homepage';


function App() {
  return (
    // <Like/>
    // <HomePage/>
    <Home/>
  );
}

export default App;
