import React from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
// import LazyLoad from "react-lazy-load";

export const Cards = () => {
  return (
    <>
      <div className="flex justify-center">
        <div className="md:w-[500px] w-[320px]">
          {/* <LazyLoad> */}
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold">Selenium</h3>
                Automated Testing Script for a e-commerce Website
              </CardContent>
            </Card>
          </div>
          {/* </LazyLoad> */}
          {/* <LazyLoad> */}
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> Pymongo & Boto3 </h3>
                Scripts to alter mongoDB database <br></br>
                Automated scripts to upload images for a website and create
                links
              </CardContent>
            </Card>
          </div>
          {/* </LazyLoad> */}
          {/* <LazyLoad> */}
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> Docker </h3>
                Container and orchestration
              </CardContent>
            </Card>
          </div>
          {/* </LazyLoad> */}
          {/* <LazyLoad> */}
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> AWS </h3>
                AWS Batch <br></br>Containerized a python rembg and deployed it
                to AWS <br></br>
                AWS eventbridge <br></br> to trigger batch jobs when uploading
                to s3
              </CardContent>
            </Card>
          </div>
          {/* //   </LazyLoad> */}
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> Flask </h3>A CRUD application model
                for a internal 3d modeller's Website for AWS actions <br></br>
                An internal file transfer API
              </CardContent>
            </Card>
          </div>
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> React.js </h3>
                Maintained a e-commerce website <br />
                Unified Multiple product pages to a single page <br />
                Implemented a search feature for the website <br />
                Build and maintained <b>
                  backend logics and data rendering{" "}
                </b>{" "}
                for a 3d modeller's website. Built a Photosharing website to
                share <b>Nature and AI Generated images</b> <br></br>
                <a href="https://skysnap.codewithsky.in">
                  <b>
                    <i>
                      <u>SKYSNAP</u>
                    </i>
                  </b>
                </a>
              </CardContent>
            </Card>
          </div>
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> Supabase </h3>
                Used Supabase as a Backend service for the above mentioned
                internal website and Skysnap
              </CardContent>
            </Card>
          </div>
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> Node.js </h3>
                Maintaining and adding some modifications for the e-commerce
                website modules
              </CardContent>
            </Card>
          </div>
          <div className="pb-5">
            <Card>
              <CardContent>
                <h3 className="font-bold"> Next.Js </h3>
                Developing a new Website for a Interior Design platform
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
