import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaNode, FaAws, FaPython, FaReact } from "react-icons/fa";
import {
  SiDocker,
  SiSupabase,
  SiDjango,
  SiFlask,
  SiMongodb,
  SiSelenium,
} from "react-icons/si";

export const TimeLine = () => {
  return (
    // <div className="flex justify-center items-center">
    //   <div className="w-[90%] bg-zinc-500 ">
        <VerticalTimeline>
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            icon={""}
          >
            Started as a Manual tester
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#007BFF", color: "#fff" }}
            icon={<SiSelenium />}
          >
            Selenium Automation Tester
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#FFC107", color: "#fff" }}
            icon={<SiMongodb />}
          >
            Pymongo
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#6C757D", color: "#fff" }}
            icon={<FaPython />}
          >
            Boto3
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#DC3545", color: "#fff" }}
            icon={<SiDocker />}
          >
            Docker
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#20C997", color: "#fff" }}
            icon={<FaAws />}
          >
            AWS
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#F8F9FA", color: "black" }}
            icon={<SiFlask />}
          >
            Flask
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#E83E8C", color: "#fff" }}
            icon={<SiDjango />}
          >
            Django (Beginner)
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#FD7E14", color: "#fff" }}
            icon={<FaReact />}
          >
            React.js
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#343A40", color: "#fff" }}
            icon={<SiSupabase />}
          >
            Supabase
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#6F42C1", color: "#fff" }}
            icon={<FaNode />}
          >
            Node.js
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "#28A745", color: "#fff" }}
            icon={""}
          >
            Next.js
          </VerticalTimelineElement>
        </VerticalTimeline>
    //   </div>
    // </div>
  );
};
